<template>
    <v-dialog
        v-model="dialog"
        max-width="900"
    >
        <v-toolbar
            dense
            dark
            flat
            color="primary"
        >
            <v-toolbar-title>
                Estudio socioeconómico
            </v-toolbar-title>
            <v-spacer/>
            <v-icon @click="dialog=false">mdi-close-box</v-icon>
        </v-toolbar>

        <v-card>
            <v-form
                ref="formEstudio"
                v-model="formValid"
            >
                <v-card-text>
                    <v-stepper v-model="paso">
                        <v-stepper-header>
                            <v-stepper-step step="1" @click="paso=1">Recursos</v-stepper-step>
                            <v-stepper-step step="2" @click="paso=2">Vivienda</v-stepper-step>
                            <v-stepper-step step="3" @click="paso=3">Salud</v-stepper-step>
                            <v-stepper-step step="4" @click="paso=4">Diagnóstico</v-stepper-step>
                        </v-stepper-header>


                        <v-stepper-items>
                            <v-stepper-content step="1" editable>
                                <span class="text-subtitle-1"><strong>Ingresos</strong></span>
                                <v-row>
                                    <v-col sm=3 cols="12">
                                        <v-text-field
                                            v-model.number="esteEstudio.dependientesEconomico"
                                            label="Dependientes"
                                            type="number"
                                            :rules="[value => value>=0 || 'Debe ser mayor o igual a 0.']"
                                        />
                                    </v-col>
                                </v-row>
                                
                                <v-row>
                                    <v-col sm=3 cols="12" v-for="itm in ingresos" :key="itm.id">
                                        <v-text-field
                                            v-model.number="esteEstudio[itm.model]"
                                            prepend-inner-icon="mdi-currency-usd"
                                            :label="itm.label"
                                            type="number"
                                            :rules="[value => value>=0 || 'Debe ser mayor o igual a 0.']"
                                            value="0"
                                        />
                                    </v-col>
                                    
                                </v-row>
                                <v-divider/>
                                <span class="text-subtitle-1"><strong>Egresos</strong></span>
                                <v-row>
                                    <v-col sm=3 cols="12" v-for="itm in egresos" :key="itm.id">
                                        <v-text-field
                                            v-model.number="esteEstudio[itm.model]"
                                            prepend-inner-icon ="mdi-currency-usd"
                                            :label="itm.label"
                                            type="number"
                                            :rules="[value => value>=0 || 'Debe ser mayor o igual a 0.']"
                                            value="0"
                                        />
                                    </v-col>
                                </v-row>

                                <v-select
                                    v-model.number="esteEstudio.ocupacionEconomico"
                                    label="Ocupación del principal proveedor"
                                    :items="opciones.ocupacionEconomico"
                                    :rules="[value => value>=0 || 'Seleccione uno']"
                                />

                            </v-stepper-content>

                            <v-stepper-content step="2" editable>
                                <span class="text-subtitle-1"><strong>Vivienda</strong></span>
                                <v-row>
                                    <v-col sm=6 cols="12" v-for="itm in vivienda" :key="itm.id">
                                        <v-select
                                            v-model.number="esteEstudio[itm.model]"
                                            :label="itm.label"
                                            :items="opciones[itm.items]"
                                            :rules="[value => value>=0 || 'Seleccione uno']"
                                        />
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="3" editable>
                                <span class="text-subtitle-1"><strong>Estado de salud</strong></span>
                                <v-row>
                                    <v-col sm=6 cols="12" v-for="itm in salud" :key="itm.id">
                                        <v-select
                                            v-model.number="esteEstudio[itm.model]"
                                            :label="itm.label"
                                            :items="opciones[itm.items]"
                                            :rules="[value => value>=0 || 'Seleccione uno']"
                                        />
                                    </v-col>
                                </v-row>
                                
                                <v-checkbox
                                    v-model.number="esteEstudio.otrosSalud"
                                    :false-value='0'
                                    :true-value='1'
                                    label="El paciente tiene otros problemas de salud que son tratados en otra institución"
                                />


                            </v-stepper-content>

                            <v-stepper-content step="4" editable>
                                <v-textarea
                                    v-model.number="esteEstudio.diagnosticoSocial"
                                    class="mt-2"
                                    label="Diagnóstico social"
                                    outlined
                                    :rules="[value => !!value || 'Requerido.']"
                                    
                                />
                                <v-text-field
                                    v-model.number="esteEstudio.encuestado"
                                    label="Persona encuestada"
                                    :rules="[value => !!value || 'Requerido.']"
                                />
                            </v-stepper-content>

                        </v-stepper-items>

                    </v-stepper>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="success" :disabled="!formValid" @click="guarda" :loading="loadingGuarda">Guardar</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>


    </v-dialog>
</template>

<script>
export default {
    props:{
        editaNivelDialog:Boolean,
        paciente:Object,
    },

    data:()=>({
        formValid:false,
        loadingGuarda:false,
        esteEstudio:{},
        paso:1,

        ingresos:[
            {id:1, label:'Del padre', model:'i1'},
            {id:2, label:'Del la madre', model:'i2'},
            {id:3, label:'Del los hijos', model:'i3'},
            {id:4, label:'Del otros', model:'i4'},
        ],
        egresos:[
            {id:1, label:'Alimentos', model:'e1'},
            {id:2, label:'Vivienda', model:'e2'},
            {id:3, label:'Agua', model:'e3'},
            {id:4, label:'Electricidad', model:'e4'},
            {id:5, label:'Combustibles', model:'e5'},
            {id:6, label:'Telefonía', model:'e6'},
            {id:7, label:'Escolares', model:'e7'},
            {id:8, label:'Transportes', model:'e8'},
            {id:9, label:'Serv. domesticos', model:'e9'},
            {id:10, label:'Salud', model:'e10'},
            {id:11, label:'Otros', model:'e11'},
        ],
        vivienda:[
            {id:1, label:'Tenencia', items:'tenenciaVivienda', model:'tenenciaVivienda'},
            {id:2, label:'Tipo', items:'tipoVivienda', model:'tipoVivienda'},
            {id:3, label:'Servicios públicos', items:'publicosVivienda', model:'publicosVivienda'},
            {id:4, label:'Servicios intradomiciliarios', items:'intradomVivienda', model:'intradomVivienda'},

            {id:5, label:'Material', items:'construccionVivienda', model:'construccionVivienda'},
            {id:6, label:'Dormitorios', items:'dormitoriosVivienda', model:'dormitoriosVivienda'},
            {id:7, label:'Persona por dormitorio', items:'personasVivienda', model:'personasVivienda'},
        ],
        salud:[
            {id:1, label:'Tiempo de enfermedad (paciente)', items:'tiempoSalud', model:'tiempoSalud'},
            {id:2, label:'Familiares enfermos', items:'familiaresSalud', model:'familiaresSalud'},
        ],


        opciones:{
            ocupacionEconomico:[
                {id: 1, text:"Sin ocupación", value:0},
                {id: 2, text:"Trabajadores no calificados", value:1},
                {id: 3, text:"Jubilados y pensionados", value:2},
                {id: 4, text:"Becarios, así como trabajadores de los servicios y vendedores de comercios y mercados", value:3},
                {id: 5, text:"Oficiales, operarios y artesanos de artes mecánicas y otros oficios", value:4},
                {id: 6, text:"Operadores de instalaciones, maquinarias y montadores", value:5},
                {id: 7, text:"Agricultores y trabajadores calificados agropecuarios y pesqueros", value:6},
                {id: 8, text:"Empleados de oficina", value:7},
                {id: 9, text:"Técnicos profesionistas de nivel medio", value:8},
                {id: 10, text:"Profesionistas, científicos e intelectuales", value:9},
                {id: 11, text:"Fuerzas armadas y ejecutivos e inversionistas", value:10}
            ],
            tenenciaVivienda: [
                {id: 12, text:"Institucional, albergues, reclusorios, casas de retiro o sin vivienda", value:0},
                {id: 13, text:"Arrendada (rentada), hipotecada o con gravamen", value:1},
                {id: 14, text:"Comodato (prestada)", value:2},
                {id: 15, text:"Propia pagada", value:3}
            ],
            tipoVivienda: [
                {id: 16, text:"Sin vivienda o local no habilitado para vivienda", value:0},
                {id: 17, text:"Vecindad o cuarto de servicio o azotea", value:1},
                {id: 18, text:"Departamento o casa popular en unidades habitacionales (interés social)", value:2},
                {id: 19, text:"Departamento o casa clase media con financiamiento propio o hipoteca", value:3},
                {id: 20, text:"Departamento o casa residencial", value:5}
            ],
            publicosVivienda: [
                {id: 21, text:"De 0 a 1 servicio público", value:0},
                {id: 22, text:"2 servicios públicos", value:1},
                {id: 23, text:"3 servicios públicos", value:2},
                {id: 24, text:"4 o más servicios públicos", value:3}
            ],
            intradomVivienda: [
                {id: 25, text:"0 - 1 servicio", value:0},
                {id: 26, text:"2 servicios", value:1},
                {id: 27, text:"3 servicios", value:2},
                {id: 28, text:"4 o más servicios", value:3}
            ],
            construccionVivienda: [
                {id: 29, text:"Lámina, Madera, Material de la región", value:0},
                {id: 30, text:"Mixta", value:1},
                {id: 31, text:"Mampostería", value:2}
            ],
            dormitoriosVivienda: [
                {id: 32, text:"1 a 2", value:0},
                {id: 33, text:"3 a 4", value:1},
                {id: 34, text:"5 o más", value:2}
            ],
            personasVivienda: [
                {id: 35, text:"4 o más personas", value:0},
                {id: 36, text:"3 personas", value:1},
                {id: 37, text:"1-2 personas", value:2}
            ],
            tiempoSalud: [
                {id: 38, text:"Más de 6 meses", value:0},
                {id: 39, text:"De 3 a 6 meses", value:1},
                {id: 40, text:"Menos de 3 meses o sin co-morbilidad", value:2}
            ],
            familiaresSalud: [
                {id: 41, text:"Dos o principal proveedor económico enfermo", value:0},
                {id: 42, text:"Un enfermo", value:1},
                {id: 43, text:"Ningún enfermo", value:2}
            ],
            salarioMinimo: [
                {id: 44, text:"88.36", value:1}
            ]
        }
    }),

    methods:{
        async guarda(){
            if (!this.esteEstudio.otrosSalud){
                this.esteEstudio.otrosSalud = 0
            }
            this.loadingGuarda=true
            this.esteEstudio.paciente = this.paciente.pacienteId
            try{
                let req = await this.$http({
                    url:'/trabajoSocial/guardarEstudioSE',
                    method:'POST',
                    params:this.esteEstudio,
                })
                this.loadingGuarda=false
                if (req.data.estado == 'true'){
                    this.$emit('guardado')
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Datos guardados exitosamente',
                    })
                    this.dialog=false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingGuarda=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        dialog:{
            get(){
                return this.editaNivelDialog
            },
            set(val){
                this.$emit('update:editaNivelDialog',val)
            },
        },
    },
    watch:{
        dialog(){
            if (this.dialog == true){
                if (!this.esteEstudio.id){

                    this.esteEstudio.i1=0
                    this.esteEstudio.i2=0
                    this.esteEstudio.i3=0
                    this.esteEstudio.i4=0

                    this.esteEstudio.e1=0
                    this.esteEstudio.e2=0
                    this.esteEstudio.e3=0
                    this.esteEstudio.e4=0
                    this.esteEstudio.e5=0
                    this.esteEstudio.e6=0
                    this.esteEstudio.e7=0
                    this.esteEstudio.e8=0
                    this.esteEstudio.e9=0
                    this.esteEstudio.e10=0
                    this.esteEstudio.e11=0

                    this.esteEstudio.encuestado=this.paciente.responsable+'. '+this.paciente.parentescoResponsableNom
                }
                
            } else {
                this.paso=1
                this.$refs.formEstudio.reset()
            }
        },
    },
}
</script>

<style>

</style>